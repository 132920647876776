<script setup>
import { useActive } from "vue-use-active-scroll";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { storeToRefs, mapActions } from "pinia";
import { useMainStore } from "~/stores";

const router = useRouter();
const route = useRoute();
const { t, locale, setLocale } = useI18n();
//const lang = useCookie("i18n_redirected", { maxAge: 15778800 });
const { findKey } = useTranslate();
const { mobile, sm, xs, smAndDown } = useDisplay();
const easingFunction = useEasingFn();
const { headers, showPolicy, sub_pages } = storeToRefs(useMainStore());
const drawer = ref(false);
const config = useRuntimeConfig();
const abuse_report = config.public.abuse_report == 1;

const headerMenu = computed(() => {
  return findKey(headers.value);
});

const nyelv = computed(() => {
  if (locale.value == "hu") {
    return { next: "en", lang: "hu", flag: "gb" };
  } else if (locale.value == "en") {
    return { next: "de", lang: "en", flag: "de" };
  } else if (locale.value == "de") {
    return { next: "hu", lang: "de", flag: "hu" };
  }

  /*   if (locale.value == "hu") {
    return { next: "en", lang: "hu", flag: "gb" };
  } else if (locale.value == "en") {
    return { next: "de", lang: "en", flag: "de" };
  } else if (locale.value == "de") {
    return { next: "hr", lang: "de", flag: "hr" };
  } else if (locale.value == "hr") {
    return { next: "sk", lang: "hr", flag: "sk" };
  } else if (locale.value == "sk") {
    return { next: "hu", lang: "sk", flag: "hu" };
  }
 */
});

const changeLang = () => {
  setLocale(nyelv.value.next);
};

const OnlineOrder = () => {
  navigateTo("https://rendeles.vargaszarnyas.hu", {
    external: true,
    open: { target: "_blank" },
  });
};

const targets = computed(() => headerMenu.value.map(({ href }) => href));

const { isActive, setActive } = useActive(targets, {
  root: null, // HTMLElement | null
  jumpToFirst: true,
  jumpToLast: true,
  overlayHeight: 91,
  minWidth: 0,
  replaceHash: true,
  edgeOffset: {
    first: 0,
    last: 500,
  },
  boundaryOffset: {
    toTop: 0,
    toBottom: 0,
  },
});

/* watch(showPolicy, (currentValue, oldValue) => {
  if (currentValue) {
    setActive("/");
    router.replace("/");
    console.log("Scroll??");
    scrollToTop();
  }
}); */

onMounted(() => {
  // console.log("header", router, route);
  // nuxt.config!!!
  //if (lang.value != undefined) setLocale(lang.value);
});
</script>

<template>
  <div class="nocopy" oncontextmenu="return false">
    <v-navigation-drawer style="position: fixed !important;" color="white" v-model="drawer" temporary>
      <v-list class="nav-menu">
        <v-list-item class="nav-link text-dark text-center" :class="{ active: isActive(nav.href) }"
          v-for="(nav, index) in headerMenu" :key="index" :link="true" :title="nav.title" :to="`/#${nav.href}`"
          @click="drawer = false" v-smooth-scroll="{
            duration: 600,
            offset: -85,
          }" color="text-vsz-piros">
        </v-list-item>
      </v-list>
      <v-footer :app="true" class="mx-auto text-center">
        <v-list-item class="w-100 px-0 mx-0 mb-16">
          <v-btn @click="OnlineOrder" flat block color="warning">
            {{ t("OnlineOrder") }}
          </v-btn>
        </v-list-item>
      </v-footer>
    </v-navigation-drawer>

    <v-app-bar app flat class="app-header navbar-light header1 px-0 px-md-16" color="bg-white" elevation="1"
      :scroll-behavior="smAndDown ? 'hide' : ''" scroll-threshold="100">
      <template v-slot:prepend>
        <LcLogoAppLogo />
      </template>

      <div v-if="!sm" class="navigation1 navbar-light ml-auto">
        <ul class="navbar-nav d-flex" min-height="auto">
          <li class="nav-item" v-for="nav in headerMenu" :key="nav.title" text>
            <v-menu v-if="nav.sub_menu" transition="slide-y-transition" :no-click-animation="true" :offset="10">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" id="menu-activator" :ripple="false" type="text"
                  class="nav-link font-weight-medium" :class="{ 'text-vsz-piros': isActive(nav.href) }">
                  {{ nav.title }}
                </v-btn>
              </template>
              <v-list class="py-0 my-0">
                <v-list-item v-for="(item, index) in nav.sub_menu" :key="index" :value="index" :to="sub_pages.includes(
                  router.currentRoute?.value.fullPath.replaceAll('/', '')
                )
                  ? item.href
                  : item.href
                  " v-smooth-scroll="{
                    duration: 600,
                    offset: -85,
                  }" color="text-vsz-piros" :width="260"
                  :disabled="!abuse_report && item.href.includes('abuse-report')">
                  <v-list-item-title class="nav-link font-weight-medium">
                    <span class="text-wrap font-14" :class="router.currentRoute.value.fullPath == item.href
                      ? 'text-vsz-piros'
                      : 'text-vsz-mustar'
                      ">
                      {{ item.title }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- :href="`#${nav.href}`" -->
            <NuxtLink v-else-if="
              sub_pages.includes(
                router.currentRoute?.value.fullPath.replaceAll('/', '')
              )
            " v-show="nav.href != 'home' || router.currentRoute?.value.path != '/'
              " :href="sub_pages.includes(
                router.currentRoute?.value.fullPath.replaceAll('/', '')
              )
                ? '/#' + nav.href
                : '#' + nav.href
                " v-smooth-scroll class="nav-link font-weight-medium" :class="{ active: isActive(nav.href) }">
              <span> {{ nav.title }}</span>
            </NuxtLink>

            <a v-else v-show="nav.href != 'home' || router.currentRoute?.value.path != '/'
              " :href="sub_pages.includes(
                router.currentRoute?.value.fullPath.replaceAll('/', '')
              )
                ? '/#' + nav.href
                : '#' + nav.href
                " v-smooth-scroll class="nav-link font-weight-medium" :class="{ active: isActive(nav.href) }">
              <span> {{ nav.title }}</span>
            </a>
          </li>
        </ul>
      </div>

      <v-btn v-if="!smAndDown" :ripple="false" icon variant="plain" @click="changeLang">
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <country-flag shadow v-bind="props" class="ma-0" :country="nyelv.flag" size="normal" />
          </template>
          <!-- :country="locale == 'en' ? 'hu' : 'gb'" -->
          <!-- {{ t("switchLang", 1, { locale: locale == "en" ? "hu" : "en" }) }} -->
          {{ t("switchLang", 1, { locale: nyelv.next }) }}
        </v-tooltip>
        <!-- <v-icon color="vsz-piros">mdi-translate</v-icon> -->
      </v-btn>

      <v-btn href="https://rendeles.vargaszarnyas.hu" target="_blank"
        class="btn-vsz-gradient px-6 ml-4 mr-0 d-md-flex d-none text-white font-weight-medium" variant="outlined"
        rounded="xl" elevation="6" color="white" width="170" v-if="!sm">
        {{ t("OnlineOrder") }}
      </v-btn>

      <v-app-bar-nav-icon width="30" :ripple="false"
        class="d-md-none d-sm-flex drawer-icon text-vsz-mustar ml-auto mr-2"
        @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
  </div>
</template>

<style lang="scss" scoped>
.nav-link.active {
  color: rgb(var(--v-theme-vsz-piros)) !important;
  font-weight: 800 !important;
}

.font-14 {
  font-size: 14px;
  font-family: "Outfit", sans-serif;
}
</style>

<style lang="scss">
.nav-menu>.v-list-item--variant-text .v-list-item__overlay {
  background: transparent !important;
}
</style>
